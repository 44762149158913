import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useApi } from '../../../../hooks/api';
import AdminPage from '../../../../components/adminPage';
import PageLoading from '../../../../components/pageLoading';
import ErrorHeading from '../../../../components/errorHeading';
import { getResourceText } from '../../../../lib/language';
import LabelInput from '../../../../components/labelInput';
import LabelCheckboxInput from '../../../../components/labelCheckboxInput';
import LabelSelect from '../../../../components/labelSelect';
import { formatDateWithTime } from '../../../../lib/date';
import '../../../../sass/_admin-users.scss';

const AdminUsers = ({ userId }) => {
  const [user, setUser] = useState();
  const [organisations, setOrganisations] = useState();
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [isActive, setIsActive] = useState();
  const [isSystemAdmin, setIsSystemAdmin] = useState();
  const [lastResourceAccessed, setLastResourceAccessed] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [organisation, setOrganisation] = useState();
  const [organisationError, setOrganisationError] = useState();
  const [isChangePassword, setIsChangePassword] = useState();
  const [credentials, setCredentials] = useState();

  const { admin: { getUser, putUser, getOrganisations } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const [{ status, json }, { status: organisationsStatus, json: organisationsData }] = await Promise.all([getUser(userId), getOrganisations()]);

      if (status === 200 && organisationsStatus === 200) {
        setUser(json);
        setEmail(json.email);
        setIsSystemAdmin(json.isSystemAdmin);
        setIsActive(json.isActive);
        setOrganisation(json.organisationId);

        const { resource, section, week } = json.lastResourceAccessed || {};
        let text;

        if (resource) {
          text = resource.resourceName;
          if (section) {
            text = `${text}   /   ${section.resourceName}`;
            if (week) {
              text = `${text}   /   ${week.resourceName}`;
            }
          }
        }

        setLastResourceAccessed(text);

        setOrganisations([{ label: '-' }, ...organisationsData.map(({ resourceName, resourceId }) => ({ label: resourceName, value: resourceId }))]);
      } else {
        setError(true);
      }
    };

    getData().catch(() => setError(true));
  }, [getOrganisations, getUser, userId]);

  const updateUser = async (event) => {
    event.preventDefault();
    setApiError(false);
    const data = {
      email,
      isActive,
      isSystemAdmin,
      organisationId: organisation,
      isChangePassword,
    };
    const { status, json: { credentials } = {} } = await putUser(userId, data);

    if (status === 200) {
      if (credentials) {
        setCredentials(credentials);
      } else {
        await navigate('/admin/users');
      }
    } else {
      setApiError(true);
    }
  };

  return (
    <AdminPage withBack>
      <div className="admin-edit-user-container">
        <h1 className="admin-edit-user-container__main-heading">Users</h1>
        <h2 className="admin-edit-user-container__sub-heading">{userId}</h2>
        {!user && !error ? <PageLoading/> : null}
        {error ? <ErrorHeading text="Error getting user"/> : null}
        {!error && user ? (
          <div className="admin-edit-user-container__body-container">
            <form onSubmit={(event) => updateUser(event)}>
              <LabelInput labelKey="userId" classNamePrefix="admin-edit-user-container" value={userId} readOnly={true}/>
              <LabelInput labelKey="email" classNamePrefix="admin-edit-user-container" value={email} onChange={setEmail} error={emailError}/>
              <LabelCheckboxInput labelKey="isActive" onChange={setIsActive} classNamePrefix="admin-edit-user-container" checked={isActive}/>
              <LabelCheckboxInput labelKey="isSystemAdmin" onChange={setIsSystemAdmin} classNamePrefix="admin-edit-user-container" checked={isSystemAdmin}/>
              <LabelSelect labelKey="organisation" onChange={setOrganisation} options={organisations} value={organisation} classNamePrefix="admin-edit-user-container"
                           error={organisationError}/>
              <LabelCheckboxInput labelKey="resetPassword" onChange={setIsChangePassword} classNamePrefix="admin-edit-user-container" checked={isChangePassword}/>
              {credentials ? <LabelInput labelKey="password" value={credentials} readOnly withCopyButton classNamePrefix="admin-edit-user-container"/> : null}
              <LabelInput labelKey="lastLogin" classNamePrefix="admin-edit-user-container" value={formatDateWithTime(user?.lastLoginDate) || getResourceText('notLoggedInYet')}
                          readOnly={true}/>
              <LabelInput labelKey="lastResourceAccessed" classNamePrefix="admin-edit-user-container" value={lastResourceAccessed} readOnly={true}/>
              <LabelInput labelKey="created" classNamePrefix="admin-edit-user-container" value={formatDateWithTime(user?.createdDate)} readOnly={true}/>

              <div className="admin-edit-user-container__actions">
                {apiError ? <span
                  className="admin-edit-user-container__action-error">{getResourceText('updateUsersApiError')}</span> : null}
                <button type="button" className="btn admin-btn admin-edit-user-container__action"
                        onClick={async () => await navigate(`/admin/users`)}>{getResourceText('cancel')}</button>
                <button type="submit"
                        className="btn admin-btn admin-edit-user-container__action">{getResourceText('updateUser')}</button>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminUsers;